import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { ShowRefundRequest } from '@/react/components/refund/shared/show-refund-request.component';
import { HistoryRequestOrderDetails } from './history-request-order-details';

export const RefundRequestOrderDetail = ({
  refundEntities,
}: {
  refundEntities: any;
}) => {
  return (
    <>
      {refundEntities.resourceApproval && refundEntities.refund_request && (
        <>
          <Box
            height={'100%'}
            width={'100%'}
            display="flex"
            alignItems="center"
            p={2}
            my={2}
            sx={(theme) => ({
              minHeight: 156,
              border: theme.palette.neutral[300],
              borderStyle: 'solid',
              borderWidth: 1,
              backgroundColor: theme.palette.neutral[50],
            })}
          >
            <Grid container px={2} rowSpacing={{ xs: 1, md: 2 }}>
              <Grid item xs={12} mb={2} style={{ textAlign: 'left' }}>
                <Typography
                  variant="body1"
                  style={{ fontWeight: '600' }}
                  color="primary.200"
                >
                  Detalhes do pedido
                </Typography>
              </Grid>
              <ShowRefundRequest
                resource_approval={refundEntities.resourceApproval}
              />
            </Grid>
          </Box>

          <Box
            height={'100%'}
            width={'100%'}
            display="flex"
            alignItems="center"
            p={2}
            my={2}
            sx={(theme) => ({
              minHeight: 156,
              border: theme.palette.neutral[300],
              borderStyle: 'solid',
              borderWidth: 1,
              backgroundColor: theme.palette.neutral[50],
            })}
          >
            <Grid container px={2} rowSpacing={{ xs: 1, md: 2 }}>
              <Grid item xs={12} mb={2} style={{ textAlign: 'left' }}>
                <Typography
                  variant="body1"
                  style={{ fontWeight: '600' }}
                  color="primary.200"
                >
                  Histórico
                </Typography>
              </Grid>
              <HistoryRequestOrderDetails
                refund_request={refundEntities.refund_request}
              />
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};
