import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, Stack, Typography } from '@mui/material';

import { TagSelect } from '@/react/components/idp/shared';
import { useRecommendedSkills } from '@/react/data/idp/useRecommendedSkills';
import { StepActions as WizardStepActions } from '@/react/components/wizard';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useWizard } from '@/react/components/wizard/state';
import { WizardStepComponent } from '@/react/components/wizard/interfaces';
import { CreatePlanWizardSteps } from '@/react/components/idp/create-plan';
import { z } from 'zod';
import { ProfileSkill } from '@/react/data/recommendation/interfaces';

interface skill {
  label: string;
  value: string;
  confidence: number;
  checked: boolean;
}

export const validationSchema = z.object({
  skillsList: z
    .object({
      label: z.string(),
      value: z.string(),
      confidence: z.number(),
      checked: z.boolean(),
    })
    .array()
    .min(1, 'Selecione pelo menos uma habilidade para continuar.')
    .max(18),
});

const mapRecommendationsWithCheckedStatus = (
  recommendations: skill[],
  selectedSkills: skill[]
) => {
  const selectedSkillsMap = new Map(
    selectedSkills.map((item) => [item.value, true])
  );
  return recommendations.map((targetItem) => ({
    ...targetItem,
    checked: selectedSkillsMap.get(targetItem.value) ?? targetItem.checked,
  }));
};

const formatSkillsToTagSelectOptions = (
  skills: (ProfileSkill & { checked: boolean })[]
) =>
  skills.map((skill) => ({
    label: skill.name_pt,
    value: skill.name_en,
    confidence: skill.confidence,
    checked: skill.checked,
  }));

const formatTagSelectOptionsToSkills = (skills: skill[]) =>
  skills.map((skill) => ({
    name_en: skill.value,
    name_pt: skill.label,
    confidence: skill.confidence,
  }));

export const PlanSkillSelectionForm: React.ElementType<WizardStepComponent> = ({
  onNext,
  onPrevious,
}) => {
  const { values } = useWizard();
  const defaultValues = {
    skillsList: values[CreatePlanWizardSteps.SKILL_SELECTION] || [],
  };
  const { formState, handleSubmit, setValue, trigger, watch } = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: zodResolver(validationSchema),
  });

  const { recommendations, isFetching } = useRecommendedSkills();
  const [skillsList, setSkillsList] = useState([]);

  useEffect(() => {
    const watchedValues = watch();

    const formattedRecommendationsForTagSelect =
      formatSkillsToTagSelectOptions(recommendations);

    const formattedSelectedSkillsForTagSelect = formatSkillsToTagSelectOptions(
      watchedValues.skillsList
    );

    const formattedRecommendations = (
      watchedValues.skillsList.length > 0
        ? mapRecommendationsWithCheckedStatus(
            formattedRecommendationsForTagSelect,
            formattedSelectedSkillsForTagSelect
          )
        : formattedRecommendationsForTagSelect
    ).sort((a, b) => b.confidence - a.confidence);

    setSkillsList(formattedRecommendations);
  }, [isFetching]);

  const setSelectedSkills = (skills) => {
    setSkillsList(skills);
    const selectedSkills = skills.filter((skill) => skill.checked);

    setValue('skillsList', selectedSkills, {
      shouldValidate: true,
    });
  };

  const handleNext = async (data) => {
    trigger('skillsList');
    const formattedSkills = formatTagSelectOptionsToSkills(data.skillsList);

    if (onNext) {
      onNext({ stepValues: formattedSkills });
    }
  };

  return (
    <>
      <Box mt={3}>
        <Typography variant="h6">
          Quais habilidades você deseja desenvolver com esse plano?
        </Typography>
        <Typography variant="body3">
          Selecione até 5 habilidades para que dê tempo de desenvolvê-las de
          forma adequada.
        </Typography>
      </Box>

      <Box mt={3}>
        <form onSubmit={handleSubmit(handleNext)}>
          <Stack spacing={2}>
            {skillsList.length > 0 && (
              <TagSelect options={skillsList} setOptions={setSelectedSkills} />
            )}
            {formState.isSubmitted && formState.errors['skillsList'] && (
              <Alert severity="error">
                {formState.errors['skillsList'].message as string}
              </Alert>
            )}
          </Stack>
          <WizardStepActions>
            <Button color="primary" onClick={onPrevious} variant="text">
              Voltar
            </Button>
            <Button
              onClick={() => {
                trigger();
              }}
              color="primary"
              type="submit"
              variant="contained"
            >
              Próximo
            </Button>
          </WizardStepActions>
        </form>
      </Box>
    </>
  );
};
