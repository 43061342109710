import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Sentry from '@sentry/browser';
import {
  Box,
  Container,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreIcon from '@mui/icons-material/Restore';

import { ConfirmStartPlanDialog } from '@/react/components/idp/dialogs/confirm-start-plan-dialog.component';
import { ConfirmFinishPlanDialog } from '@/react/components/idp/dialogs/confirm-finish-plan-dialog.component';
import { StartPlanDisabledDialog } from '@/react/components/idp/dialogs/start-plan-disabled-dialog.component';
import { IdpClient } from '@/react/data/idp/idp-client';
import {
  PlanStatuses,
  Activity,
  ActivityStatuses,
} from '@/react/data/idp/interfaces';
import { zodResolver } from '@hookform/resolvers/zod';
import { updatePlanSchema } from '@/react/data/idp/schemas';
import {
  EstimatedEndsAt,
  StartedAt,
  EndedAt,
} from '@/react/components/idp/show-plan/date-display.component';
import {
  SectionHeader,
  PageSkeleton,
  PageContainer,
} from '@/react/components/idp/shared';
import { Action } from '@/react/components/idp/show-plan/action';
import { ConfirmRestartPlanDialog } from '@/react/components/idp/dialogs/confirm-restart-plan-dialog.component';
import { InlineEditInput } from '@/react/components/form/inline-edit-input.component';
import { ActivitiesList } from '@/react/components/idp/activities-list/activities-list';
import { usePlan, usePlanActivities } from '@/react/data/idp';
import { ConfirmDeletePlanDialog } from '@/react/components/idp/dialogs/confirm-delete-plan-dialog.component';
import { ActivityProgress } from '@/react/components/idp/activity-progress.component';
import { useSetTitle } from '@/react/hooks/useSetTitle';

enum EDITING_FIELD {
  TITLE = 'title',
  DESCRIPTION = 'description',
  DURATION_IN_MONTHS = 'duration_in_months',
}

const handleBackToPlanList = () => {
  window.location.href = '/pdi/planos';
};

export type ShowPlanProps = {
  planId: string;
};

const defaultValues = {
  title: '',
  description: '',
  duration_in_months: null,
};

const ShowPlan = ({ planId }: ShowPlanProps) => {
  const { isFetching: fetchingPlan, plan } = usePlan(planId);
  const { isFetching: fetchingActivities, activities } = usePlanActivities(
    plan?.id
  );
  const [editingField, setEditingField] = React.useState<EDITING_FIELD>(null);
  const [isDeletePlanModalOpen, setIsDeletePlanModalOpen] =
    React.useState(false);
  const inputRefs = {
    [EDITING_FIELD.TITLE]: React.useRef<HTMLInputElement>(null),
    [EDITING_FIELD.DESCRIPTION]: React.useRef<HTMLInputElement>(null),
    [EDITING_FIELD.DURATION_IN_MONTHS]: React.useRef<HTMLInputElement>(null),
  };
  const [isRestartPlanDialogOpen, setIsRestartPlanDialogOpen] =
    React.useState(false);

  const { control, formState, handleSubmit, getValues, reset } = useForm({
    defaultValues,
    resolver: zodResolver(updatePlanSchema),
  });

  useEffect(() => {
    if (
      !fetchingPlan &&
      plan.status === PlanStatuses.DRAFT &&
      plan?.profileBasedRecommendation?.id &&
      !plan?.profileBasedRecommendation?.completed_at
    ) {
      window.location.assign(
        `/pdi/planos/recomendacoes/${plan.profileBasedRecommendation.id}`
      );
    }
  }, [plan]);

  useSetTitle(plan?.title ?? 'Plano sem título');

  const handleChange = async () => {
    const values = {
      ...getValues(),
    };

    try {
      await IdpClient.updatePlan(planId, values);
      setEditingField(null);
    } catch (error) {
      Sentry.captureException(error);
      setEditingField(null);
    }
  };

  const mountDurationOptions = () => {
    const options = [];
    for (let i = 1; i <= 18; i += 1) {
      options.push(
        <MenuItem key={i} value={i}>
          {i}
        </MenuItem>
      );
    }
    return options;
  };

  const actions = [
    {
      label: 'Exportar em PDF',
      onClick: () => {
        window.print();
      },
      Icon: <PictureAsPdfIcon />,
    },
    {
      label: 'Excluir plano',
      onClick: () => {
        setIsDeletePlanModalOpen(true);
      },
      Icon: <DeleteIcon />,
    },
  ];

  if (plan?.status == PlanStatuses.CLOSED) {
    actions.push({
      label: 'Retomar plano',
      onClick: () => {
        setIsRestartPlanDialogOpen(true);
      },
      Icon: <RestoreIcon />,
    });
  }

  useEffect(() => {
    const values = {
      title: plan?.title || defaultValues.title,
      description: plan?.description || defaultValues.description,
      duration_in_months: plan?.durationInMonths
        ? plan?.durationInMonths
        : defaultValues.duration_in_months,
    };

    reset(values);
  }, [plan]);

  useEffect(() => {
    inputRefs[editingField]?.current?.focus();
  }, [editingField]);

  const handleInlineEditFieldsChange = async (
    fieldOnChange: (value: any) => void,
    value: string
  ) => {
    try {
      fieldOnChange(value);
      await handleChange();

      return true;
    } catch (error) {
      return false;
    }
  };

  const enableStart = formState.isValid && activities.length > 0;

  const InProgressDialog = enableStart
    ? ConfirmStartPlanDialog
    : StartPlanDisabledDialog;

  const activitiesSeparatedByStatus =
    Activity.getAmountOfActivitiesByStatus(activities);

  const hasProgressBar =
    plan?.status === PlanStatuses.IN_PROGRESS && activities.length > 0;

  return (
    <PageContainer>
      <Box sx={{ py: 3 }}>
        {fetchingPlan ? (
          <PageSkeleton />
        ) : (
          plan && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SectionHeader
                  actions={actions}
                  handleBackNavigation={handleBackToPlanList}
                  title={
                    plan.status === PlanStatuses.DRAFT ? 'Novo plano' : 'Voltar'
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="title"
                  render={({ field, fieldState: { error } }) => (
                    <FormControl error={!!error} fullWidth>
                      <FormLabel htmlFor="title">
                        <Typography variant="body3" fontWeight="bold">
                          Título
                        </Typography>
                      </FormLabel>
                      <Typography
                        component={InlineEditInput}
                        data-testid="title-input"
                        disabled={plan.status === PlanStatuses.CLOSED}
                        error={!!error}
                        fullWidth
                        onAccept={(value) =>
                          handleInlineEditFieldsChange(field.onChange, value)
                        }
                        onClickOutside={(value) =>
                          handleInlineEditFieldsChange(field.onChange, value)
                        }
                        placeholder="Insira um título"
                        value={field.value}
                        variant="body3"
                      />
                    </FormControl>
                  )}
                />
              </Grid>
              {hasProgressBar && (
                <Grid item xs={12}>
                  <Typography gutterBottom variant="body3" fontWeight="bold">
                    Progresso
                  </Typography>
                  <ActivityProgress
                    data={{
                      active:
                        activitiesSeparatedByStatus[
                          ActivityStatuses.IN_PROGRESS
                        ],
                      done: activitiesSeparatedByStatus[
                        ActivityStatuses.COMPLETED
                      ],
                      remaining:
                        activitiesSeparatedByStatus[ActivityStatuses.PENDING],
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="description"
                  render={({ field, fieldState: { error } }) => (
                    <FormControl error={!!error} fullWidth>
                      <FormLabel htmlFor="description">
                        <Typography variant="body3" fontWeight="bold">
                          Descrição
                        </Typography>
                      </FormLabel>
                      <Typography
                        component={InlineEditInput}
                        data-testid="title-input"
                        disabled={plan.status === PlanStatuses.CLOSED}
                        error={!!error}
                        fullWidth
                        onAccept={(value) =>
                          handleInlineEditFieldsChange(field.onChange, value)
                        }
                        onClickOutside={(value) =>
                          handleInlineEditFieldsChange(field.onChange, value)
                        }
                        placeholder="Insira uma descrição"
                        value={field.value}
                        variant="body3"
                      />
                    </FormControl>
                  )}
                />
              </Grid>
              {plan.status !== PlanStatuses.DRAFT && (
                <Grid item xs={12}>
                  <StartedAt plan={plan} />
                </Grid>
              )}
              <Grid item xs={12}>
                <Controller
                  name="duration_in_months"
                  control={control}
                  render={({ field }) => {
                    return (
                      <FormControl>
                        <FormLabel htmlFor="duration_in_months">
                          <Typography variant="body3" fontWeight="bold">
                            Duração em meses
                          </Typography>
                        </FormLabel>
                        <Select
                          {...field}
                          variant="outlined"
                          displayEmpty
                          id="duration_in_months"
                          disabled={plan.status === PlanStatuses.CLOSED}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            handleChange();
                          }}
                          renderValue={(value) =>
                            value || 'Selecione uma duração em meses'
                          }
                          size="small"
                          value={field.value || ''}
                        >
                          {mountDurationOptions()}
                        </Select>
                      </FormControl>
                    );
                  }}
                />
              </Grid>
              {plan.status === PlanStatuses.IN_PROGRESS && (
                <Grid item xs={12}>
                  <EstimatedEndsAt plan={plan} />
                </Grid>
              )}
              {plan.status === PlanStatuses.CLOSED && (
                <Grid item xs={12}>
                  <EndedAt plan={plan} />
                </Grid>
              )}

              {plan.status === PlanStatuses.IN_PROGRESS && (
                <Grid item xs={12}>
                  <Action
                    label="Concluir Plano"
                    description="Conclua o plano para sinalizar que você alcançou seus objetivos e está pronto para encerrar esta jornada!"
                    Dialog={({ open, onClose }) => (
                      <ConfirmFinishPlanDialog
                        open={open}
                        onClose={onClose}
                        plan={plan}
                      />
                    )}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <ActivitiesList
                  activities={activities}
                  loading={fetchingActivities}
                  plan={plan}
                />
              </Grid>

              {plan.status === PlanStatuses.DRAFT && (
                <Grid item xs={12}>
                  <Action
                    label="Iniciar plano"
                    description="Inicie o plano para poder atualizar e acompanhar o progresso das atividades. Não se preocupe, você ainda poderá editar o plano como quiser."
                    alignRight
                    Dialog={({ open, onClose }) => (
                      <InProgressDialog
                        open={open}
                        onClose={onClose}
                        plan={plan}
                      />
                    )}
                  />
                </Grid>
              )}

              <ConfirmDeletePlanDialog
                plan={plan}
                open={isDeletePlanModalOpen}
                onClose={() => setIsDeletePlanModalOpen(false)}
              />
              <ConfirmRestartPlanDialog
                plan={plan}
                open={isRestartPlanDialogOpen}
                onClose={() => setIsRestartPlanDialogOpen(false)}
              />
            </Grid>
          )
        )}
      </Box>
    </PageContainer>
  );
};

export { ShowPlan };
