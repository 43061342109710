import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  LinearProgress,
  TextField,
  Tabs,
  Tab,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { nanoid } from 'nanoid';

import Pagination from '@/react/components/pagination/pagination.component';
import { useResourceApprovalsToReviewList } from '@/react/data/subsidy/useResourceApprovalsToReviewList';
import { RefundResourceStatus } from '@/react/data/subsidy/interfaces';
import { EmptyList } from '@/react/components/refund/empty-list.component';

const ListResourceApprovalDataTable = () => {
  const [page, setPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [filterStatus, setFilterStatus] = useState('all');
  const [filterIdentifier, setFilterIdentifier] = useState('');
  const [sortDirection, setSortDirection] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const requestParams = {
    status: filterStatus,
    page: page,
    per_page: 10,
    identifier: filterIdentifier,
  };

  const { resourceApprovals, totalPages, isFetching, error } =
    useResourceApprovalsToReviewList(requestParams);

  useEffect(() => {
    if (totalPages) {
      setTotalPageCount(totalPages);
    }
  }, [page, totalPages, filterStatus, filterIdentifier]);

  const handlePageChange = (value) => {
    setPage(value);
  };

  const handleFilterChange = (_event, newValue) => {
    if (typeof newValue === 'string') {
      setFilterIdentifier('');
      setFilterStatus(newValue);
      setPage(1);
    }
  };

  const handleSort = () => {
    const newDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    setSortDirection(newDirection);
  };

  const handleRowAction = ({ resourceApprovalUuid, status }) => {
    const currentPath = window.location.pathname;
    return status === RefundResourceStatus.pending_approval
      ? {
          variantType: 'contained' as 'contained' | 'outlined' | 'text',
          text: 'Analisar pedido',
          href: `${currentPath}/${resourceApprovalUuid}/avaliar-pedido`,
        }
      : {
          variantType: 'outlined' as 'contained' | 'outlined' | 'text',
          text: 'Ver detalhes',
          href: `${currentPath}/${resourceApprovalUuid}/detalhes`,
        };
  };

  const tabLabels = [
    { label: 'Todos', value: 'all' },
    { label: 'Pendentes', value: 'pending' },
    { label: 'Aprovados', value: 'approved' },
    { label: 'Recusados', value: 'rejected' },
    { label: 'Cancelados', value: 'canceled' },
  ];

  function handleFilterIdentifier(): void {
    if (inputValue === '') {
      return;
    }

    setFilterIdentifier(inputValue);
    setPage(1);
  }
  return (
    <>
      <Box
        display="contents"
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        alignItems={{ xs: 'stretch', md: 'center' }}
        p={2}
        gap={2}
      >
        <Box
          display="flex"
          gap={1}
          flexGrow={1}
          maxWidth={{ xs: '100%', md: 'auto' }}
        >
          <TextField
            label="Pesquise por email ou CPF"
            variant="outlined"
            size="medium"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                handleFilterIdentifier();
              }
            }}
            sx={{ flexShrink: 0, width: 'auto', borderRadius: 0 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="search"
                    onClick={handleFilterIdentifier}
                  >
                    <SearchIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            sx={{ borderRadius: 0 }}
            onClick={handleFilterIdentifier}
          >
            Pesquisar
          </Button>
        </Box>

        <Tabs
          value={filterStatus}
          onChange={handleFilterChange}
          textColor="primary"
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          sx={{
            '& .MuiSvgIcon-root': {
              width: '20px',
            },
            '& .MuiButtonBase-root': {
              borderRadius: 0,
              width: '20px',
            },
            marginTop: { xs: '15px' },
          }}
        >
          {tabLabels.map(({ label, value }) => (
            <Tab
              key={nanoid()}
              label={label}
              value={value}
              sx={({ palette }) => ({
                width: { xs: `${100 / tabLabels.length}%`, md: 'auto' },
                border: `1px solid ${palette.neutral?.[300] || '#E0E0E0'}`,
                background: palette.neutral?.[50] || '#FAFAFA',
                color: palette.primary?.[900] || '#1A202C',
                '&.Mui-selected': {
                  background: palette.primary?.[100] || '#E3F2FD',
                  border: `1px solid ${palette.primary?.[900] || '#1A202C'}`,
                  borderBottom: `1px solid ${palette.primary?.[900] || '#1A202C'}`,
                },
              })}
            />
          ))}
        </Tabs>
      </Box>

      <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '20px' }}>
        <TableContainer>
          <Table
            sx={{
              '& .MuiTableHead-root .MuiTableCell-root': {
                paddingTop: '30px',
                paddingBottom: '30px',
                position: 'relative',
                '&:after': {
                  content: "''",
                  position: 'absolute',
                  right: 0,
                  top: '20%',
                  bottom: '20%',
                  width: '1px',
                  backgroundColor: 'rgba(224, 224, 224, 1)',
                },
                '&:last-child:after': {
                  display: 'none',
                },
              },
              '& .MuiTableBody-root .MuiTableCell-root': {
                borderBottom: 'none',
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <Box display="flex" alignItems="center" gap={1}>
                    Data de Solicitação
                    <IconButton size="small" onClick={handleSort}>
                      {sortDirection === 'asc' ? (
                        <ArrowUpward />
                      ) : (
                        <ArrowDownward />
                      )}
                    </IconButton>
                  </Box>
                </TableCell>
                <TableCell>Nome</TableCell>
                <TableCell>Usuário</TableCell>
                <TableCell>Valor reembolsável</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching && !error ? (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <LinearProgress />
                  </TableCell>
                </TableRow>
              ) : resourceApprovals.length > 0 ? (
                resourceApprovals.map((row) => {
                  const { variantType, text, href } = handleRowAction({
                    resourceApprovalUuid: row.uuid,
                    status: row.status,
                  });

                  return (
                    <TableRow key={nanoid()}>
                      <TableCell>
                        {new Date(row.requested_at).toLocaleDateString('pt-BR')}
                      </TableCell>
                      <TableCell>{row.user.name}</TableCell>
                      <TableCell>{row.user.email}</TableCell>
                      <TableCell>
                        {new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(Number(row.requested_refundable_amount))}
                      </TableCell>
                      <TableCell>
                        <Button
                          href={href}
                          variant={variantType}
                          sx={{ borderRadius: 0, width: '100%' }}
                        >
                          {text}
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <EmptyList />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {resourceApprovals.length > 0 && (
          <Box display="flex" justifyContent="flex-end" my={2}>
            <Pagination
              currentPage={page}
              totalPages={totalPageCount}
              pageChange={handlePageChange}
            />
          </Box>
        )}
      </Paper>
    </>
  );
};

export default ListResourceApprovalDataTable;
