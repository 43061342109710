import { useQuery } from '@tanstack/react-query';
import { RecommendationClient } from '@/react/data/recommendation/recommendation-client';
import { ProfileSkill } from '../recommendation/interfaces';

const queryKey = ['useRecommendedSkills'];

export function useRecommendedSkills() {
  const result = useQuery({
    queryKey: [...queryKey],
    queryFn: async () => {
      return RecommendationClient.getProfile();
    },
    refetchOnMount: false,
  });

  let rawRecommendations: ProfileSkill[] = [];

  if (result.isFetched) {
    const recommendationsCurrentJob =
      result.data.current_job.gpt_skill_subcategories ?? [];
    const recommendationsDesiredJob =
      result.data.desired_job.gpt_skill_subcategories ?? [];

    const uniqueRecommendations = new Map();
    [...recommendationsCurrentJob, ...recommendationsDesiredJob].forEach(
      (item) => {
        if (!uniqueRecommendations.has(item.name_en)) {
          uniqueRecommendations.set(item.name_en, item);
        }
      }
    );

    rawRecommendations = Array.from(uniqueRecommendations.values());
  }

  const recommendations = rawRecommendations.map((skill) => ({
    ...skill,
    checked: false,
  }));

  return {
    error: !!result.error,
    isFetching: result.isFetching,
    recommendations,
  };
}
