import React from 'react';

import {
  Alert,
  AlertTitle,
  Box,
  Container,
  Stack,
  Typography,
} from '@mui/material';

export const BannerStatus = () => {
  return (
    <Container maxWidth={false} disableGutters>
      <Stack
        spacing={2}
        sx={(theme) => ({
          width: '100vw',
          position: 'relative',
        })}
      >
        <Box sx={{ width: '60%' }}>
          <Alert
            severity="error"
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              px: { xs: 2, md: 4 },
              gap: theme.spacing(1),
              '& .MuiAlert-icon': {
                marginRight: theme.spacing(1),
              },
            })}
          >
            <AlertTitle
              sx={(theme) => ({
                fontWeight: theme.typography.fontWeightBold,
                mb: 0,
                fontSize: theme.typography.body1.fontSize,
              })}
            >
              Documentação recusada
            </AlertTitle>
            <Typography
              variant="body3"
              sx={(theme) => ({
                fontSize: theme.typography.body3.fontSize,
                color: theme.palette.text.primary,
              })}
            >
              Os documentos foram recusados e o pedido não será reembolsado.
            </Typography>
          </Alert>
        </Box>
      </Stack>
    </Container>
  );
};
