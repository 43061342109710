import { useQuery } from '@tanstack/react-query';
import { RefundRequestResponse } from '@/react/data/subsidy/interfaces';
import { SubsidyClient } from './subsidy-client';

type RefundRequestUuids = {
  refundRequestUuid: string;
};

export function useRefundRequest(requestParams: RefundRequestUuids) {
  const result = useQuery({
    enabled: !!requestParams.refundRequestUuid,
    queryKey: ['useRefundRequest', requestParams],
    queryFn: async ({ signal }) => {
      return await SubsidyClient.getRefundRequest({
        refundRequestUuid: requestParams.refundRequestUuid,
        signal,
      });
    },
    refetchOnMount: false,
  });

  const refundRequest = result?.data?.refund_request || null;

  return {
    error: !!result.error,
    isFetching: result.isFetching,
    isLoading: result.isLoading,
    refundRequest,
  };
}
