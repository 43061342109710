import React from 'react';

import {
  PageContainer,
  SectionHeader,
} from '@/react/components/refund/refund-management/shared';
import { RefundRequestOrderDetail } from './refund-request-order-detail';
import { BannerStatus } from '@/react/components/refund/shared/banner-status';
import { useResourceApproval } from '@/react/data/subsidy/useResourceApproval';
import { useRefundRequest } from '@/react/data/subsidy/useRefundRequest';
import {
  FormControl,
  Typography,
  FormHelperText,
  Skeleton,
  Stack,
  Box,
  Grid,
} from '@mui/material';
import { RefundRequestStatus } from '@/react/data/subsidy/interfaces';

const handleBackButton = (resource_approval_uuid) => {
  window.location.assign(
    `/empresa/gestao-de-reembolsos/${resource_approval_uuid}/detalhes`
  );
};

export const RefundRequestDetails = ({
  resourceApprovalUuid,
  refundRequestUuid,
}: {
  resourceApprovalUuid: string;
  refundRequestUuid: string;
}) => {
  const refundEntitiesUuids = {
    refundRequestUuid,
    resourceApprovalUuid,
  };

  const { isLoading: isLoadingResourceApprova, resourceApproval } =
    useResourceApproval(refundEntitiesUuids);

  const { isLoading: isLoadingRefundRequest, refundRequest: refund_request } =
    useRefundRequest({
      refundRequestUuid: refundEntitiesUuids.refundRequestUuid,
    });

  const FieldSkeleton = ({ padding }) => {
    return (
      <FormControl
        fullWidth
        sx={{
          pl: padding ? padding : null,
          pr: padding ? padding : null,
        }}
      >
        <Stack spacing={1}>
          <Typography component="div" variant="body3">
            <Skeleton width="100%" />
          </Typography>
          <Skeleton height={53} variant="rounded" />
        </Stack>
        <FormHelperText />
      </FormControl>
    );
  };

  const FieldWrapper = ({
    loading,
    padding,
    children,
  }: {
    loading: boolean;
    padding?: number;
    children?: React.ReactNode;
  }) => {
    return loading ? <FieldSkeleton padding={padding} /> : <>{children}</>;
  };

  return (
    <>
      {!resourceApproval && !refund_request ? (
        <Box
          display="flex"
          alignItems="center"
          p={2}
          my={2}
          mr={4}
          ml={4}
          sx={(theme) => ({
            border: theme.palette.neutral[300],
            borderStyle: 'solid',
            borderWidth: 1,
            backgroundColor: theme.palette.neutral[50],
          })}
        >
          <FieldWrapper
            loading={isLoadingResourceApprova && isLoadingRefundRequest}
          >
            <Grid container px={2} rowSpacing={{ xs: 1, md: 2 }}></Grid>
          </FieldWrapper>
        </Box>
      ) : (
        <>
          {[
            RefundRequestStatus.rejected,
            RefundRequestStatus.cancelled,
          ].includes(resourceApproval?.status)}
          <BannerStatus />
          <PageContainer>
            <SectionHeader
              title="Detalhes do reembolso"
              handleBackNavigation={() =>
                handleBackButton(resourceApprovalUuid)
              }
            />
            <RefundRequestOrderDetail
              refundEntities={{ resourceApproval, refund_request }}
            />
          </PageContainer>
        </>
      )}
    </>
  );
};

export default RefundRequestDetails;
