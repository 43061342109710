import * as React from 'react';
import { Grid, Typography, Divider, Box } from '@mui/material';

import { formatDate } from '@/react/utils/date';
import { RefundItem } from '@/react/data/subsidy/interfaces';

export const HistoryRequestOrderDetails = ({
  refund_request,
}: {
  refund_request: RefundItem;
}) => {
  const findApprovedHistory = (refund_request) => {
    const approvedItem = refund_request?.history?.find(
      (item) => item.approved === true
    );
    return approvedItem
      ? { message: approvedItem.message, approved: approvedItem.approved }
      : null;
  };

  const ApprovedMessages = ({ history }: { history: any[] }) => {
    return (
      <Box sx={{ width: '100%' }}>
        {history?.length > 0 ? (
          history.map((item, index) => (
            <Box
              key={index}
              sx={{
                backgroundColor: '#FCFDB3',
                width: '100%',
                marginBottom: '10px',
              }}
            >
              <Typography variant="body3" display="block">
                {item.message}
              </Typography>
              <Typography variant="body3" display="block">
                {item.decision_comment}
              </Typography>
            </Box>
          ))
        ) : (
          <Typography variant="body3">Nenhum histórico disponível</Typography>
        )}
      </Box>
    );
  };

  return (
    <>
      <Grid
        item
        xs={12}
        sx={({ spacing }) => ({
          paddingTop: spacing(0.5),
          paddingBottom: spacing(2),
        })}
      >
        <Divider />
      </Grid>
      <Grid container rowSpacing={{ xs: 1, md: 2 }}>
        <Grid item xs={12} md={3}>
          <Typography variant="body3" fontWeight={600}>
            Solicitação do item
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant="body3">
            {formatDate(refund_request?.created_at) || '--'}
          </Typography>
        </Grid>

        <Grid item xs={12} md={3}>
          <Typography variant="body3" fontWeight={600}>
            Aprovação do item
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant="body3">
            {findApprovedHistory(refund_request)?.message || '--'}
          </Typography>
        </Grid>

        <Grid item xs={12} md={3}>
          <Typography variant="body3" fontWeight={600}>
            Envio de comprovantes
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant="body3">
            {formatDate(refund_request?.created_at) || '--'}
          </Typography>
        </Grid>

        <Grid item xs={12} md={3}>
          <Typography variant="body3" fontWeight={600}>
            Validação de comprovantes
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <ApprovedMessages history={refund_request?.history} />
        </Grid>
      </Grid>
    </>
  );
};
